<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="content-card">
        <div slot="header" class="clearfix-title">
          <span>{{ detail.orderCode ? '订单号：' + detail.orderCode : '订单号：——' }}</span>
          <span>{{ detail.orderStatus }}</span>
        </div>
        <div class="baseInfo">
          <div class="header">
            <div class="head">
              <img :src="detail.imgs" alt="" class="" />
            </div>
          </div>
          <div class="info">
            <div>
              <span class="value" style="font-weight: 600; font-size: 16px; line-height: 30px">
                {{ detail.skuName || '——' }}
              </span>
            </div>
            <div>
              <span class="label">商品编号:</span>
              <span class="value">{{ detail.skuNo || '——' }}</span>
            </div>
            <div>
              <span class="label">商品数量:</span>
              <span class="value">{{ detail.skuNum || '——' }}</span>
            </div>
          </div>
          <div class="points">
            <span class="value">
              {{ detail.allSkuScore }}
            </span>
            <span class="label">积分</span>
          </div>
        </div>
      </ykc-detail-plus>
      <ykc-detail-plus>
        <div slot="header" clearfix-title>
          <span class="title">收货信息</span>
        </div>
        <ykc-detail-item-plus label="收货人姓名">
          <span>{{ detail.receiveName || '——' }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="收货人电话">
          <span>{{ detail.receivePhone || '——' }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="收货地址">
          <span>{{ detail.receiveAddress || '——' }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-detail-plus v-if="detail.orderStatus === '已发货'">
        <div slot="header">
          <span class="title" clearfix-title>物流信息</span>
        </div>
        <ykc-detail-item-plus label="发货时间">
          <span>{{ detail.deliveryTime || '——' }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="物流单号">
          <span>{{ detail.logisticsOrderNo || '——' }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import { pointsMall } from '@/service/apis';

  export default {
    name: 'exchangeDetail',
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        detail: {},
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
        const reqParams = this.$route.query;
        console.log('reqParams', reqParams);
        pointsMall.detail(reqParams).then(res => {
          console.log('detail', res);
          this.detail = res || {};
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    > div {
      margin-bottom: 10px;
    }
    .clearfix-title {
      padding: 0 8px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }
    .baseInfo {
      display: flex;
      font-size: 14px;
      .points {
        text-align: right;
        position: absolute;
        right: 10px;
        top: 45%;
        .value {
          color: #409eff;
          font-size: 24px;
          font-weight: 600;
        }
        .label {
          color: #c0c4cc;
        }
      }
      .header {
        width: 120px;
        display: flex;
        justify-content: center;

        .head {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .info {
        padding-left: 14px;
        font-size: 14px;
        font-weight: 400;
        width: 310px;
        word-break: break-all;
        overflow: auto;
        white-space: normal;
        .label {
          color: #727272;
          margin-right: 10px;
          line-height: 24px;
        }
        .value {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
</style>
